<template>
  <MainLayout>
    <template #content>
      <div
        v-if="!IS_LOADING.status"
        class="content-container flex flex-col w-full px-8"
      >
        <div data-v-6d1a851d="" class="font-medium mb-1 text-2xl text-center">
          Meine Wallet
        </div>

        <span class="border-b-2 pb-6 text-center text-sm">
          Übersicht deiner Gutscheine
        </span>

        <WalletList
          v-if="!isTourism"
          class="mb-3 mt-4"
          :role="AUTH_USER.role.name"
          :data="USER_VOUCHERS.data"
          :withPagination="true"
          :currentPage="USER_VOUCHERS.current_page"
          :lastPage="USER_VOUCHERS.last_page"
          :isCart="false"
          :withQR="true"
          :withCartDetail="false"
          @onPaginate="onPaginateVouchers($event)"
        />
        
        <template v-if="isTourism">
          <div
            v-if="employeeVouchers.total"
            class="gap-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-10"
          >
            <div
              v-for="(employeeVoucher, index) in employeeVouchers.data"
              :key="index"
              class="pb-10 cursor-pointer employee-voucher-list"
              @click="employeeVoucherRedirect(employeeVoucher.employee_voucher_code)"
            >
              <div
                class="flex justify-center"
              >
                <div class="w-80 bg-white rounded-lg">
                  <div
                    :style="{
                      backgroundImage: 'url(' +
                        onSetCustomImage(
                          employeeVoucher.data_json != null &&
                            employeeVoucher.data_json.background_image
                            ? employeeVoucher.data_json.background_image
                            : employeeVoucher.custom_background_image ||
                            employeeVoucher.background_image
                        ) +
                      ')',
                      backgroundSize: 'cover',
                    }"
                    style="background-position: center center; background-repeat: no-repeat;"
                    class="w-80 h-80 rounded-t-lg"
                  ></div>

                  <div class="px-5 pt-5">
                    <div class="text-lg font-semibold">{{ employeeVoucher.title || 'Gutscheintitel' }}</div>
                    <div class="text-base">{{ (employeeVoucher.seller && employeeVoucher.seller.username) || 'N/A' }}</div>

                    <hr class="mt-3 mb-5">

                    <div class="flex gap-3 mb-5">
                      <div class="flex flex-col w-1/2">
                        <div class="text-xs">Preis</div>
                        <div class="text-base font-semibold">
                          {{ employeeVoucher.employee_value || '' }}
                        </div>
                      </div>

                      <div class="flex gap-1 w-1/2">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </MainLayout>
</template>
<script>
import MainLayout from '_layouts/subdomain';
import SearchInputField from '_components/Form/SearchInputField';
import WalletList from '_components/List/Modules/WalletList/';

export default {
  components: {
    MainLayout,
    SearchInputField,
    WalletList,
  },
  data() {
    return {
      search: '',
      params: {
        keyword: '',
        page: 1,
        paginate: 30,
        user_id: null,
        status: 'completed',
      },
      isTourism: false,
      employeeVouchers: []
    };
  },
  computed: {
    AUTH_USER() {
      return this.$store.getters.AUTH_USER;
    },
    USER_VOUCHERS() {
      return this.$store.getters.USER_VOUCHERS;
    },
    IS_LOADING() {
      return this.$store.getters.IS_LOADING;
    },
    // IS_LOAD_MORE()
    // {
    //   return this.$store.getters.IS_LOAD_MORE
    // },
  },
  // watch: {
  //   async IS_LOAD_MORE(newVal)
  //   {
  //     if( newVal ) {
  //       await this.onLoadData({
  //         ...this.params,
  //         page: this.params.page + 1
  //       })
  //       await this.$store.commit('SET_IS_LOAD_MORE', false)
  //     }
  //   },
  // },
  mounted() {
    (async () => {
      try {
        await this.$store.commit('SET_USER_VOUCHERS', []);
        // await this.$store.commit('SET_IS_INFINITE_LOAD', true)
        this.params.user_id = this.AUTH_USER.data.id;
        await this.$store.commit('SET_IS_LOADING', { status: 'open' });

        this.isTourism = this.wildcard == 'tourism' ? true : false
        
        if(this.wildcard !== 'tourism') {
          await this.onFetchSearchUserVouchers();
        } else {
          const { vouchers } = await this.$store.dispatch('FETCH_EMPLOYEE_WALLET')

          this.employeeVouchers = vouchers
        }

        await this.$store.commit('SET_IS_LOADING', { status: 'close' });
      } catch (err) {
        await this.$store.commit('SET_IS_LOADING', { status: 'close' });
      }
    })();
  },
  beforeDestroy() {
    (async () => {
      await this.$store.commit('SET_IS_INFINITE_LOAD', false);
    })();
  },
  methods: {
    async onPaginateVouchers(action) {
      let params = {
        ...this.params,
        subdomain: this.wildcard,
        page: action === 'prev' ? this.params.page - 1 : this.params.page + 1,
      };
      await this.$store.commit('SET_USER_VOUCHERS', []);
      await this.onLoadData(params);
    },
    async onLoadData(data, fromSearch = false) {
      await this.$store.commit('SET_IS_PROCESSING', { status: 'open' });
      this.params = {
        ...this.params,
        ...data,
        page: this.params.keyword != '' ? 1 : data.page,
      };
      if (fromSearch) {
        await this.$store.commit('SET_USER_VOUCHERS', []);
      }
      await this.onFetchSearchUserVouchers();
      await this.$store.commit('SET_IS_PROCESSING', { status: 'close' });
    },
    async onFetchSearchUserVouchers() {
      try {
        const data = await this.$store.dispatch('FETCH_SEARCH_USER_VOUCHERS', {
          ...this.params,
          subdomain: this.wildcard,
        });
        if (data.user_vouchers.next_page_url == null) {
          await this.$store.commit('SET_IS_INFINITE_LOAD', false);
        }
      } catch (err) {
        console.log('err', err);
      }
    },
    onSetCustomImage(value) {
      if (!value) return '';
      return value.search('base64') < 0
        ? `${process.env.VUE_APP_API_BASE_URL}/storage/${value}`
        : value;
    },
    employeeVoucherRedirect(id) {
      const userUUID = this.AUTH_USER.data.employee_uuid

      const link = `/wallet/voucher/${userUUID}/${id}`

      window.location.href = link
    }
  },
};
</script>
<style lang="css" scoped></style>
