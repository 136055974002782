<template>
  <div
    id="input-field-component"
    class="w-full"
    :class="[
      { 'mb-5 ' : !iconLeft },
      { 'mb-2' : iconLeft }
    ]"
  >
    <ValidationProvider
      :name="id"
      :rules="rules"
      tag="div"
      class="flex mx-auto w-11/12"
      :class="[
        { 'w-11/12': !fullWidthItem },
        { 'w-full': fullWidthItem }
      ]"
    >
      <template class="test" #default="{ errors }">
        <Header5 v-if="label != ''" :label="label" />
        <div
          class="flex flex-col mx-auto relative w-full"
          :class="[
            { 'max-w-md': !fullWidthItem }
          ]"
        >
          <svg
            v-if="iconLeft && !isMapIcon"
            class="absolute h-5 icon ml-3 mr-6 mt-3 text-gray-500 w-5"
          >
            <use xlink:href="/icons/sprite.svg#search" />
          </svg>

          <svg
            v-if="iconLeft && isMapIcon"
            xmlns="http://www.w3.org/2000/svg"
            class="bi bi-geo-alt-fill absolute icon ml-3 mr-6 mt-3 text-gray-500"
            width="20"
            height="20"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
          </svg>

          <input
            :id="id"
            ref="inputField"
            v-model="keyword"
            :name="id"
            :type="type"
            class="input-field rounded-full py-2"
            :class="[
              font,
              { 'text-red-500 border-red-500': errors && errors.length > 0 },
              { 'pr-12 px-3': !iconLeft },
              { 'pl-10': iconLeft }
            ]"
            :value="value"
            :placeholder="placeholder"
          />

          <svg
            v-if="!iconLeft"
            class="absolute h-5 icon mr-6 mt-3 right-0 text-peach w-5"
          >
            <use xlink:href="/icons/sprite.svg#search" />
          </svg>

          <a
            v-if="submitButton"
            href="javascript:void(0)"
            class="company-bg-color px-5 py-3 rounded-md text-sm text-white mt-3 text-center"
            @click="onSearch"
            >Suchen</a
          >
        </div>
        <ErrorMessage class="mt-1" :errors="[...errors, ...errorMessages]" />
      </template>
    </ValidationProvider>
  </div>
</template>
<script>
import ErrorMessage from './FieldErrorMessage';
import Header5 from '_components/Headers/Header5';

export default {
  components: {
    ErrorMessage,
    Header5,
  },
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
    type: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String],
      default: null,
    },
    rules: {
      type: String,
      default: '',
    },
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
    submitButton: {
      type: Boolean,
      default: true,
    },
    font: {
      type: String,
      default: 'text-sm',
    },
    iconLeft: {
      type: Boolean,
      default: false,
    },
    isMapIcon: {
      type: Boolean,
      default: false
    },
    fullWidthItem: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      keyword: '',
    };
  },
  watch: {
    value(newVal) {
      this.keyword = newVal;
    },
  },
  mounted() {
    let input = document.getElementById(this.id);
    let timeout = null;
    let self = this;
    input.addEventListener('keyup', function(e) {
      clearTimeout(timeout);
      timeout = setTimeout(function() {
        self.$emit('input', e.target.value);
      }, 1000);
    });
  },
  methods: {
    onUpdateField() {
      // let input = document.getElementById(this.id);
      // let timeout = null;
      // input.addEventListener('keyup', function (e) {
      //   clearTimeout(timeout);
      //   timeout = setTimeout(function () {
      //     console.log('Input Value:', e.target.value);
      //   }, 3000);
      // });
      // this.$emit('input', this.$refs.inputField.value);
    },
    onSearch() {
      if (this.$route.name == 'vouchers-search') {
        this.$emit('input', this.keyword);
      } else {
        this.$router.push('vouchers/search');
      }
    },
  },
};
</script>
<style lang="css" scoped>
.search-icon {
  position: absolute;
  left: 0;
  padding: 9px 15px;
}
</style>
