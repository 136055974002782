var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full",class:[
    { 'mb-5 ' : !_vm.iconLeft },
    { 'mb-2' : _vm.iconLeft }
  ],attrs:{"id":"input-field-component"}},[_c('ValidationProvider',{staticClass:"flex mx-auto w-11/12",class:[
      { 'w-11/12': !_vm.fullWidthItem },
      { 'w-full': _vm.fullWidthItem }
    ],attrs:{"name":_vm.id,"rules":_vm.rules,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [(_vm.label != '')?_c('Header5',{attrs:{"label":_vm.label}}):_vm._e(),_c('div',{staticClass:"flex flex-col mx-auto relative w-full",class:[
          { 'max-w-md': !_vm.fullWidthItem }
        ]},[(_vm.iconLeft && !_vm.isMapIcon)?_c('svg',{staticClass:"absolute h-5 icon ml-3 mr-6 mt-3 text-gray-500 w-5"},[_c('use',{attrs:{"xlink:href":"/icons/sprite.svg#search"}})]):_vm._e(),(_vm.iconLeft && _vm.isMapIcon)?_c('svg',{staticClass:"bi bi-geo-alt-fill absolute icon ml-3 mr-6 mt-3 text-gray-500",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"20","height":"20","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"}})]):_vm._e(),((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.keyword),expression:"keyword"}],ref:"inputField",staticClass:"input-field rounded-full py-2",class:[
            _vm.font,
            { 'text-red-500 border-red-500': errors && errors.length > 0 },
            { 'pr-12 px-3': !_vm.iconLeft },
            { 'pl-10': _vm.iconLeft }
          ],attrs:{"id":_vm.id,"name":_vm.id,"placeholder":_vm.placeholder,"type":"checkbox"},domProps:{"value":_vm.value,"checked":Array.isArray(_vm.keyword)?_vm._i(_vm.keyword,_vm.value)>-1:(_vm.keyword)},on:{"change":function($event){var $$a=_vm.keyword,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.keyword=$$a.concat([$$v]))}else{$$i>-1&&(_vm.keyword=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.keyword=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.keyword),expression:"keyword"}],ref:"inputField",staticClass:"input-field rounded-full py-2",class:[
            _vm.font,
            { 'text-red-500 border-red-500': errors && errors.length > 0 },
            { 'pr-12 px-3': !_vm.iconLeft },
            { 'pl-10': _vm.iconLeft }
          ],attrs:{"id":_vm.id,"name":_vm.id,"placeholder":_vm.placeholder,"type":"radio"},domProps:{"value":_vm.value,"checked":_vm._q(_vm.keyword,_vm.value)},on:{"change":function($event){_vm.keyword=_vm.value}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.keyword),expression:"keyword"}],ref:"inputField",staticClass:"input-field rounded-full py-2",class:[
            _vm.font,
            { 'text-red-500 border-red-500': errors && errors.length > 0 },
            { 'pr-12 px-3': !_vm.iconLeft },
            { 'pl-10': _vm.iconLeft }
          ],attrs:{"id":_vm.id,"name":_vm.id,"placeholder":_vm.placeholder,"type":_vm.type},domProps:{"value":_vm.value,"value":(_vm.keyword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.keyword=$event.target.value}}}),(!_vm.iconLeft)?_c('svg',{staticClass:"absolute h-5 icon mr-6 mt-3 right-0 text-peach w-5"},[_c('use',{attrs:{"xlink:href":"/icons/sprite.svg#search"}})]):_vm._e(),(_vm.submitButton)?_c('a',{staticClass:"company-bg-color px-5 py-3 rounded-md text-sm text-white mt-3 text-center",attrs:{"href":"javascript:void(0)"},on:{"click":_vm.onSearch}},[_vm._v("Suchen")]):_vm._e()]),_c('ErrorMessage',{staticClass:"mt-1",attrs:{"errors":errors.concat( _vm.errorMessages)}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }