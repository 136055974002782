var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MainLayout',{scopedSlots:_vm._u([{key:"content",fn:function(){return [(!_vm.IS_LOADING.status)?_c('div',{staticClass:"content-container flex flex-col w-full px-8"},[_c('div',{staticClass:"font-medium mb-1 text-2xl text-center",attrs:{"data-v-6d1a851d":""}},[_vm._v(" Meine Wallet ")]),_c('span',{staticClass:"border-b-2 pb-6 text-center text-sm"},[_vm._v(" Übersicht deiner Gutscheine ")]),(!_vm.isTourism)?_c('WalletList',{staticClass:"mb-3 mt-4",attrs:{"role":_vm.AUTH_USER.role.name,"data":_vm.USER_VOUCHERS.data,"withPagination":true,"currentPage":_vm.USER_VOUCHERS.current_page,"lastPage":_vm.USER_VOUCHERS.last_page,"isCart":false,"withQR":true,"withCartDetail":false},on:{"onPaginate":function($event){return _vm.onPaginateVouchers($event)}}}):_vm._e(),(_vm.isTourism)?[(_vm.employeeVouchers.total)?_c('div',{staticClass:"gap-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-10"},_vm._l((_vm.employeeVouchers.data),function(employeeVoucher,index){return _c('div',{key:index,staticClass:"pb-10 cursor-pointer employee-voucher-list",on:{"click":function($event){return _vm.employeeVoucherRedirect(employeeVoucher.employee_voucher_code)}}},[_c('div',{staticClass:"flex justify-center"},[_c('div',{staticClass:"w-80 bg-white rounded-lg"},[_c('div',{staticClass:"w-80 h-80 rounded-t-lg",staticStyle:{"background-position":"center center","background-repeat":"no-repeat"},style:({
                    backgroundImage: 'url(' +
                      _vm.onSetCustomImage(
                        employeeVoucher.data_json != null &&
                          employeeVoucher.data_json.background_image
                          ? employeeVoucher.data_json.background_image
                          : employeeVoucher.custom_background_image ||
                          employeeVoucher.background_image
                      ) +
                    ')',
                    backgroundSize: 'cover',
                  })}),_c('div',{staticClass:"px-5 pt-5"},[_c('div',{staticClass:"text-lg font-semibold"},[_vm._v(_vm._s(employeeVoucher.title || 'Gutscheintitel'))]),_c('div',{staticClass:"text-base"},[_vm._v(_vm._s((employeeVoucher.seller && employeeVoucher.seller.username) || 'N/A'))]),_c('hr',{staticClass:"mt-3 mb-5"}),_c('div',{staticClass:"flex gap-3 mb-5"},[_c('div',{staticClass:"flex flex-col w-1/2"},[_c('div',{staticClass:"text-xs"},[_vm._v("Preis")]),_c('div',{staticClass:"text-base font-semibold"},[_vm._v(" "+_vm._s(employeeVoucher.employee_value || '')+" ")])]),_c('div',{staticClass:"flex gap-1 w-1/2"})])])])])])}),0):_vm._e()]:_vm._e()],2):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }